<template>
  <div ref="main-dashborad" class="main-body">
    <div class="row topmainrow">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="topmainlayer card">
          <!-- (PSM BASED) -->
          <!-- (안전보건관리체계 솔루션 Andami –) -->
          <!-- (SHMS) -->
          <div class="title">{{$label('LBL0000323')}} <span class="title1">{{$label('LBL0000324')}}</span> <span class="title2">{{$label('LBL0000325')}} –</span> <span class="title3">{{$label('LBL0000326')}}</span></div>
          <div class="mainbtnscreen">
            <q-btn flat round color="white" :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'" @click="clickFullScreen">
              <q-tooltip v-if="fullscreen">
                <!-- 원래대로 -->
                {{$label('LBL0000327')}}
              </q-tooltip>
              <q-tooltip v-else>
                <!-- 전체화면 -->
                {{$label('LBL0000328')}}
              </q-tooltip>
            </q-btn>
          </div>
          <div class="mainbtns">
            <!-- 유해화학물질목록 -->
            <q-btn flat round color="orange-5" icon="science" @click="goPage('/psi/hsl/hslManage')"><q-tooltip>{{$label('LBL0000329')}}</q-tooltip></q-btn>
            <!-- 작업허가서 -->
            <q-btn flat round color="orange-5" icon="handyman" @click="goPage('/sop/swp/safeWorkPermit')"><q-tooltip>{{$label('LBL0000214')}}</q-tooltip></q-btn>
            <!-- 교육계획 및 결과 -->
            <q-btn flat round color="orange-5" icon="school" @click="goPage('/sop/edu/result/educationResult')"><q-tooltip>{{$label('LBL0000330')}}</q-tooltip></q-btn><br>
            <!-- 개선관리 -->
            <q-btn flat round color="orange-5" style="margin-top:-10px" icon="assessment" @click="goPage('/sop/ibm/impr')"><q-tooltip>{{$label('LBL0000331')}}</q-tooltip></q-btn>
            <!-- 설비점검 -->
            <q-btn flat round color="orange-5" style="margin-top:-10px" icon="pending_actions" @click="goPage('/sop/mim/inspection/equipmentInspection')"><q-tooltip>{{$label('LBL0000332')}}</q-tooltip></q-btn>
            <!-- 설비정비 -->
            <q-btn flat round color="orange-5" style="margin-top:-10px" icon="more_time" @click="goPage('/sop/mim/maintenance/equipmentMaintenance')"><q-tooltip>{{$label('LBL0000333')}}</q-tooltip></q-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9" style="margin-top:10px">
        <div class="card cardcontents maincardlayer">
          <div class="card-header non-label-icon mapheader maincardheader">
            <q-chip class="chip-task"
              square clickable 
              :outline="mapObj.activeTask!==1"
              :color="mapObj.activeTask===1 ? 'green-6' : 'white'" 
              :text-color="mapObj.activeTask===1 ? 'white' : 'black'" 
              icon="directions"
              @click="() => mapObj.activeTask = 1">
             <!-- 작업허가서 -->
              {{$label('LBL0000214')}}
            </q-chip>
            <q-chip class="chip-task"
              square clickable
              :outline="mapObj.activeTask!==2"
              :color="mapObj.activeTask===2 ? 'amber-7' : 'white'" 
              :text-color="mapObj.activeTask===2 ? 'white' : 'black'" 
              icon="directions"
              @click="() => mapObj.activeTask = 2">
             <!-- 위험성평가 -->
              {{$label('LBL0000334')}}
            </q-chip>
            <q-chip class="chip-task"
              square clickable 
              :outline="mapObj.activeTask!==3"
              :color="mapObj.activeTask===3 ? 'light-blue-6' : 'white'" 
              :text-color="mapObj.activeTask===3 ? 'white' : 'black'" 
              icon="directions"
              @click="() => mapObj.activeTask = 3">
             <!-- MOC -->
              {{$label('LBL0000335')}}
            </q-chip>
            <q-chip class="chip-task"
              square clickable 
              :outline="mapObj.activeTask!==4"
              :color="mapObj.activeTask===4 ? 'deep-orange-6' : 'white'" 
              :text-color="mapObj.activeTask===4 ? 'white' : 'black'" 
              icon="directions"
              @click="() => mapObj.activeTask = 4">
             <!-- 사고 -->
              안전사고
            </q-chip>
            <q-chip class="chip-task"
              square clickable 
              :outline="mapObj.activeTask!==5"
              :color="mapObj.activeTask===5 ? 'deep-purple-6' : 'white'" 
              :text-color="mapObj.activeTask===5 ? 'white' : 'black'" 
              icon="directions"
              @click="() => mapObj.activeTask = 5">
             <!-- 설비점검 -->
              {{$label('LBL0000332')}}
            </q-chip>
            <div class="card-more main-header-input">
              <c-datepicker
                v-show="mapObj.activeTask===1"
                :range="true"
                :appendToBody="false"
                name="workPermitPeriod"
                v-model="mapObj.taskParam.workPermitPeriod"
                @datachange="getMapData"
              />
              <c-datepicker
                v-show="mapObj.activeTask===2"
                :range="true"
                :appendToBody="false"
                name="assessRiskPeriod"
                v-model="mapObj.taskParam.assessRiskPeriod"
                @datachange="getMapData"
              />
              <c-datepicker
                v-show="mapObj.activeTask===3"
                :range="true"
                :appendToBody="false"
                name="mocPeriod"
                v-model="mapObj.taskParam.mocPeriod"
                @datachange="getMapData"
              />
              <c-datepicker
                v-show="mapObj.activeTask===4"
                :range="true"
                :appendToBody="false"
                name="accidentPeriod"
                v-model="mapObj.taskParam.accidentPeriod"
                @datachange="getMapData"
              />
              <c-datepicker
                v-show="mapObj.activeTask===5"
                :range="true"
                :appendToBody="false"
                name="equipCheckPeriod"
                v-model="mapObj.taskParam.equipCheckPeriod"
                @datachange="getMapData"
              />
            </div>
             <!-- 시작 -->
             <!-- 정지 -->
            <q-btn 
              :outline="!mapObj.interval.isPause" 
              :color="mapObj.interval.isPause ? 'green-6' : 'white'" 
              :text-color="mapObj.interval.isPause ? 'white' : 'white'" 
              size="9px" class="mainmapstart"
              :label="mapObj.interval.isPause ? $comm.getLangLabel('LBL0000338') : $comm.getLangLabel('LBL0000339')"
              :icon="mapObj.interval.isPause ? 'restart_alt' : 'pause'"
              @click="pause">
             <!-- 업무영역 Rotate -->
              <q-tooltip>{{$label('LBL0000337')}}</q-tooltip>
            </q-btn>
            <!-- {{mapObj.interval.seconds}} -->
            <!-- <div class="card-more" @click="openTaskDetail">+</div> -->
          </div>
          <div class="card-body maincardbody" ref="carousel" style="padding: 15px !important;">
            <q-carousel
              v-if="mapObj.maps && mapObj.maps.length > 0"
              class="workPermit-carousel"
              swipeable
              animated
              arrows
              control-type="regular"
              control-color="grey-6"
              v-model="mapObj.slide"
              infinite
            >
              <q-carousel-slide 
                v-for="(map, idx) in mapObj.maps"
                :key="idx"
                :name="idx" 
                :img-src="map.mapSrc">
                <template>
                  <div class="mapTitleDiv" v-text="map.mapName"></div>
                  <!-- <div v-if="showMarkArea"
                    :style="{'height': imgHeight(map) }"> -->
                    <!-- 작업허가서 -->
                    <template v-if="mapObj.activeTask===1">
                      <VueDraggableResizable
                        v-for="(item, idx) in map.maps"
                        :key="idx"
                        ref="markImage"
                        class="mainMarkImage"
                        :resizable="false"
                        :parent="true"
                        :draggable="false"
                        :x="item.locationXcoordinate * mapRate"
                        :y="item.locationYcoordinate * mapRate"
                        :w="40 " :h="40"
                        :grid="[20, 20]"
                      >
                        <q-icon name="push_pin" class="blinking" />
                        <!-- <div class="markImage-abbreviation-parent"
                          :style="{ 
                            width: !item.isHover 
                              ? item.resultTypes.length > 3 ? '227px' : getWidth(item)
                              : '241px', 
                            left: getLeft(item),
                            top: getTop(item),
                          }"> -->
                        <div class="markImage-abbreviation-parent"
                          :style="{ 
                            width: '341px',
                            left: getLeft(item),
                            top: getTop(item),
                          }">
                          <!-- <div class="markImage-abbreviation" 
                            @mouseover="() => { item.isHover = true }"
                            @mouseleave="() => { item.isHover = false }"> -->
                          <div class="markImage-abbreviation">
                            <!-- <transition name="mark-list" tag="div">
                              <div v-if="!item.isHover" class="markImage-tran">
                                <q-banner v-for="(resultType, idx) in item.resultTypes.slice(0, 3)"
                                  :key="idx"
                                  dense 
                                  :class="[
                                    'markImage-abbreviation-banner',
                                    item.resultTypes.length < 4 && idx === item.resultTypes.length - 1 ? 'last-banner' : ''
                                  ]">
                                  <q-icon :name="workPermitIconName(resultType)" class="text-red" />
                                  <div>
                                    {{resultType.sopWorkTypeName}}
                                  </div>
                                </q-banner>
                                <q-banner v-if="item.resultTypes.length > 3"
                                  key="-1"
                                  dense 
                                  :class="['markImage-abbreviation-plus']">
                                  <q-icon name="add" class="text-red" />{{item.resultTypes.length - 3}}
                                  <div>
                                    <q-icon name="more_horiz" class="text-black" />
                                  </div>
                                </q-banner>
                              </div>
                            </transition> -->
                            <transition name="mark-list" tag="div">
                              <!-- <div v-show="item.isHover"> -->
                              <div v-show="true">
                                <q-banner v-for="(resultType, idx) in item.resultTypes"
                                  :key="idx"
                                  dense 
                                  :class="[idx % 2 ===1 ? 'bg-grey-3' : 'bg-grey-1', 'markImage-abbreviation-banner-detail']">
                                  <template v-slot:avatar>
                                    <q-icon :name="workPermitIconName(resultType)" class="text-red" />
                                  </template>
                                  {{resultType.sopWorkTypeName}}
                                  <q-badge :color="workPermitbadgeColor(resultType.swpStepName)" :label="resultType.swpStepName" />
                                  <q-btn class="q-ml-sm" outline round dense color="red" size="9px" label="" icon="add" @click="openWorkPermit(resultType)" />
                                  <div class="caption-work">
                                    {{resultType.workSummary}}
                                  </div>
                                </q-banner>
                              </div>
                            </transition>
                          </div>
                        </div>
                      </VueDraggableResizable>
                    </template>
                    <!-- 작업허가서를 제외한 나머지 업무 -->
                      <!-- class-name-active="my-active-class" -->
                    <VueDraggableResizable
                      v-for="(mp, idx) in map.otherMaps"
                      :key="'other' + idx"
                      :ref="'processMark' + mp.processCd"
                      class="my-class"
                      :class="mp.processCd === mapObj.process.processCd ? 
                        'my-active-class' : ''
                      "
                      style="z-index:1"
                      :parent="true"
                      :draggable="false"
                      :resizable="false"
                      :x="mp.x * mapRate"
                      :y="mp.y * mapRate"
                      :w="mp.w * mapRate" :h="mp.h * mapRate"
                      :grid="[20, 20]"
                      @activated="onClickProcess(mp)"
                    >
                      <div>{{mp.processName}}</div>
                    </VueDraggableResizable>
                    <div
                      v-for="(mp, idx) in map.otherMaps.filter(i => {
                        return i.tasks.filter(i => {
                          return i.taskType === String(mapObj.activeTask)
                        }).length > 0
                      })"
                      :key="'other-tran' + idx"
                      class="task-parent"
                      :style="setStyleTaskArea(mp)"
                      @mouseover="() => { mp.isHover = true }"
                      @mouseleave="() => { mp.isHover = false }">
                      <div v-show="mapObj.activeTask===2 
                        && mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).length > 0" class="rounded-borders task-detail"
                          style="border-top:3px solid #ffb300">
                        <q-banner v-for="(task, _idx) in mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).slice(0, 1)"
                          :key="_idx"
                          dense>
                          <div class="mainmapbannerTitle">{{task.name}}</div>
                          <!-- <q-badge color="amber-7" floating>{{mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).length}}</q-badge> -->
                          <div class="caption-work2" style="text-align: left;">
                            <!-- 평가진행 건 수 -->
                            {{$label('LBL0000340')}}: <font class="text-amber-7">{{task.actionCnt}}</font><br/>
                            <!-- 위험등록부 건 수 -->
                            {{$label('LBL0000341')}}: <font class="text-amber-7">{{task.riskRegisterCnt}}</font><br/>
                            <!-- 미조치 Punch-list 건 수 -->
                            {{$label('LBL0000342')}}: <font class="text-amber-7">{{task.notActionImprCnt}}</font>
                          </div>
                        </q-banner>
                      </div>
                      <div v-show="mapObj.activeTask===3 
                        && mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).length > 0" class="rounded-borders task-detail"
                          style="border-top:3px solid #03a9f4">
                        <q-banner v-for="(task, _idx) in mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).slice(0, 1)"
                          :key="_idx"
                          dense>
                          <div class="mainmapbannerTitle">{{task.name}}</div>
                          <!-- <q-badge color="light-blue-6" floating>{{mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).length}}</q-badge> -->
                          <div class="caption-work2" style="text-align: left;">
                            <!-- PSI진행 건 수 -->
                            {{$label('LBL0000343')}}: <font class="text-blue">{{task.psiCnt}}</font><br/>
                            <!-- 위험성평가실시 진행 건 수 -->
                            {{$label('LBL0000344')}}: <font class="text-light-blue-6">{{task.assessCnt}}</font><br/>
                            <!-- 가동전 점검(진행중/완료) -->
                            {{$label('LBL0000345')}}: <font class="text-light-blue-6">{{task.preStartCnt}}</font>
                            <!-- Punch list(진행중/완료) -->
                            {{$label('LBL0000346')}}: <font class="text-light-blue-6">{{task.punchCnt}}</font>
                          </div>
                        </q-banner>
                      </div>
                      <div v-show="mapObj.activeTask===4
                        && mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).length > 0" class="rounded-borders task-detail"
                          style="border-top:3px solid #ff5722">
                        <q-banner v-for="(task, _idx) in mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).slice(0, 1)"
                          :key="_idx"
                          dense>
                          <div class="mainmapbannerTitle">{{task.name}}</div>
                          <!-- <q-badge color="deep-orange-6" floating>{{mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).length}}</q-badge> -->
                          <div class="caption-work2" style="text-align: left;">
                            <!-- 사고종류 -->
                            {{$label('LBL0000347')}}: <font class="text-deep-orange-6">{{task.accidentKindName}}</font><br/>
                            <!-- 사고등급 -->
                            {{$label('LBL0000348')}}: <font class="text-deep-orange-6">{{task.accidentGradeName}}</font><br/>
                            <!-- 사고원인(대/중분류) -->
                            {{$label('LBL0000349')}}: <font class="text-deep-orange-6">{{task.occurrenceModeName}}</font>
                          </div>
                        </q-banner>
                      </div>
                      <div v-show="mapObj.activeTask===5
                        && mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).length > 0" class="rounded-borders task-detail"
                          style="border-top:3px solid #673ab7">
                        <q-banner v-for="(task, _idx) in mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).slice(0, 1)"
                          :key="_idx"
                          dense>
                          <div class="mainmapbannerTitle">{{task.name}}</div>
                          <!-- <q-badge color="deep-purple-4" floating>{{mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).length}}</q-badge> -->
                          <div class="caption-work2" style="text-align: left;">
                            <!-- 점검유형 -->
                            {{$label('LBL0000350')}}: <font class="text-deep-purple-4">{{task.equipmentCheckKindName}}</font><br/>
                            <!-- 점검일 -->
                            {{$label('LBL0000351')}}: <font class="text-deep-purple-4">{{task.checkDate}}</font><br/>
                            <!-- 점검자 -->
                            {{$label('LBL0000352')}}: <font class="text-deep-purple-4">{{task.checkUserName}}</font>
                          </div>
                        </q-banner>
                      </div>
                    </div>
                  <!-- </div> -->
                </template>
              </q-carousel-slide>
            </q-carousel>
            <!-- 등록된 지도가 없습니다. -->
            <el-empty v-else 
              :image-size="435"
              :description="$comm.getLangMessage('MSG0000137')"></el-empty>
          </div>
        </div>
      </div>
      <transition 
        appear
        enter-active-class="animated fadeIn"
        leave-active-class="animated backOutRight"
      >
        <div ref="taskDetailArea"
          tabindex="0"
          class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3" :style="isTaskDetailShow ? 'padding:10px !important;' : 'margin-top:15px;'"
          :class="isTaskDetailShow ? 'open-task' : ''"
          >
          <!-- @blur="blurTaskDetail"> -->
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" :style="!isTaskDetailShow ? 'padding-left:10px !important;padding-bottom:10px !important;' : ''">
              <!-- 공정현황 -->
              <c-card topClass="noborderCard" :title="(mapObj.process.processName ? '[' + mapObj.process.processName + ']' : '') + this.$comm.getLangLabel('LBL0000353')" 
                class="cardClassDetailForm main-map-card" :titleClass="isTaskDetailShow ? 'orange-5' : 'white'" :height="isTaskDetailShow ? '310px' : '310px'" :isCardIcon="false">
                <template slot="card-button">
                  <q-btn-group outline>
                    <c-btn :icon="!isTaskDetailShow ? 'arrow_back_ios_new' : 'arrow_forward_ios'" @btnClicked="openTaskDetail">
                    </c-btn>
                  </q-btn-group>
                </template>
                <template slot="card-detail">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-show="!isTaskDetailShow">
                    <q-tabs
                      v-model="maintab1"
                      dense
                      align="justify"
                      class="text-white mainDashboardTab"
                      active-color="deep-white"
                      indicator-color="deep-white"
                      inline-label
                    >
                      <!-- 부서 -->
                      <q-tab name="dept" :label="$comm.getLangLabel('LBL0000354')" />
                      <!-- 설비 -->
                      <q-tab name="equip" :label="$comm.getLangLabel('LBLEQUIP')" />
                      <!-- 화학자재 -->
                      <q-tab name="material" :label="$comm.getLangLabel('LBL0000356')" />
                    </q-tabs>
                    <q-separator />
                    <q-tab-panels v-model="maintab1" animated class="maintabbody">
                      <q-tab-panel name="dept">
                        <div class="maintabTable" v-for="(dept, deptIdx) in mapObj.process.depts"
                          :key="deptIdx" v-show="deptIdx < 6">
                          <span class="maintabTd maintabTd7" v-show="deptIdx < 5">{{dept.deptName}}</span>
                          <!-- 명 -->
                          <span class="maintabTd maintabTd3" v-show="deptIdx < 5">{{dept.workerCount}}{{$label('LBL0000357')}}</span>
                          <!-- … 외 -->
                          <!-- 개의 취급부서가 존재합니다. -->
                          <span class="maintabTd maintabTdgreen" v-show="mapObj.process.depts.length > 5 && deptIdx == 5">{{$label('LBL0000358')}} {{mapObj.process.depts.length - 5}} {{$message('MSG0000123')}}</span>
                        </div>
                        <div class="maintabTable" v-show="mapObj.process.depts.length === 0">
                          <!-- 취급부서가 존재하지 않습니다. -->
                          <span class="maintabTd maintabTdgreen">{{$message('MSG0000124')}}</span>
                        </div>
                      </q-tab-panel>
                      <q-tab-panel name="equip">
                        <div class="maintabTable" v-for="(equip, equipIdx) in mapObj.process.equips"
                          :key="equipIdx" v-show="equipIdx < 6 && !isTaskDetailShow">
                          <span class="maintabTd" v-show="equipIdx < 5">{{equip.equipmentName}}</span>
                          <!-- … 외 -->
                          <!-- 화학자재 -->
                          <span class="maintabTd maintabTdgreen" v-show="mapObj.process.equips.length > 5 && equipIdx == 5">{{$label('LBL0000358')}} {{mapObj.process.equips.length - 5}} {{$message('MSG0000125')}}</span>
                          <!-- 개의 관련설비가 존재합니다. -->
                          <span class="maintabTd maintabTdgreen" v-show="mapObj.process.equips.length == 0">{{$message('MSG0000126')}}</span>
                        </div>
                        <div class="maintabTable" v-show="mapObj.process.equips.length === 0 && !isTaskDetailShow">
                          <!-- 관련 설비가 존재하지 않습니다. -->
                          <span class="maintabTd maintabTdgreen">{{$message('MSG0000126')}}</span>
                        </div>
                      </q-tab-panel>
                      <q-tab-panel name="material">
                        <div class="maintabTable" v-for="(hazard, hazardIdx) in mapObj.process.hazardChems"
                          :key="hazardIdx" v-show="hazardIdx < 6 && !isTaskDetailShow">
                          <span class="maintabTd" v-show="hazardIdx < 5">{{hazard.materialName}}</span>
                          <!-- … 외 -->
                          <!-- 개의 취급화학자재가 존재합니다. -->
                          <span class="maintabTd maintabTdgreen" v-show="mapObj.process.hazardChems.length > 5 && hazardIdx == 5">{{$label('LBL0000358')}} {{mapObj.process.hazardChems.length - 5}} {{$message('MSG0000127')}}</span>
                        </div>
                        <div class="maintabTable" v-show="mapObj.process.hazardChems.length === 0 && !isTaskDetailShow">
                          <!-- 관련 화학자재가 존재하지 않습니다. -->
                          <span class="maintabTd maintabTdgreen">{{$message('MSG0000128')}}</span>
                        </div>
                      </q-tab-panel>
                    </q-tab-panels>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" v-show="isTaskDetailShow" style="padding-right:5px !important;">
                    <div class="maintabTable maintabTableTop">
                      <!-- 부서명 -->
                      <span class="maintabTr maintabTd7 text-center">{{$label('LBL0000359')}}</span>
                      <!-- 근로자수 -->
                      <span class="maintabTr maintabTd3 text-center">{{$label('LBL0000360')}}</span>
                    </div>
                    <div class="maintablebody">
                      <div class="maintabTable" v-for="(dept, deptIdx) in mapObj.process.depts"
                        :key="deptIdx" v-show="mapObj.process.depts.length > 0">
                        <span class="maintabTd maintabTd7 padding-left10">{{dept.deptName}}</span>
                          <!-- 명 -->
                        <span class="maintabTd maintabTd3 text-center">{{dept.workerCount}}{{$label('LBL0000357')}}</span>
                      </div>
                      <div class="maintabTabl" v-show="mapObj.process.depts.length === 0">
                          <!-- 취급부서가 존재하지 않습니다. -->
                        <span class="maintabTd maintabTdgreen">{{$message('MSG0000124')}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 maintableFulle" v-show="isTaskDetailShow" style="padding-right:5px !important;">
                    <div class="maintabTable maintabTableTop">
                      <!-- 설비명 -->
                      <span class="maintabTr maintabTd6 text-center">{{$label('LBL0000361')}}</span>
                      <!-- 설비유형 -->
                      <span class="maintabTr maintabTd4 text-center">{{$label('LBL0000362')}}</span>
                    </div>
                    <div class="maintablebody">
                      <div class="maintabTable" v-for="(equip, equipIdx) in mapObj.process.equips"
                        :key="equipIdx" v-show="mapObj.process.equips.length > 0">
                        <span class="maintabTd maintabTd6 link-line padding-left10" @click="linkEquipClick({equipmentCd:equip.equipmentCd, plantCd: equip.plantCd})">{{equip.equipmentName}}</span>
                        <span class="maintabTd maintabTd4">{{equip.equipmentTypeName}}</span>
                      </div>
                      <div class="maintabTable" v-show="mapObj.process.equips.length === 0">
                        <!-- 관련 설비가 존재하지 않습니다. -->
                        <span class="maintabTd maintabTdgreen">{{$message('MSG0000126')}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 maintableFulle" v-show="isTaskDetailShow">
                    <div class="maintabTable maintabTableTop">
                      <!-- 화학자재명 -->
                      <span class="maintabTr maintabTd6 text-center">{{$label('LBL0000363')}}</span>
                      <!-- 일취급량 -->
                      <span class="maintabTr maintabTd2 text-center">{{$label('LBL0000364')}}</span>
                      <!-- MSDS -->
                      <span class="maintabTr maintabTd2 text-center">{{$label('LBL0000365')}}</span>
                    </div>
                    <div class="maintablebody">
                      <div class="maintabTable" v-for="(hazard, hazardIdx) in mapObj.process.hazardChems"
                        :key="hazardIdx" v-show="mapObj.process.hazardChems.length > 0">
                        <span class="maintabTd maintabTd6 link-line padding-left10" @click="linkChemClick({mdmChemMaterialId:hazard.mdmChemMaterialId, plantCd: hazard.plantCd}, 'chemPsm')">{{hazard.materialName}}</span>
                        <span class="maintabTd maintabTd2 text-right">{{hazard.dailyVolume}}</span>
                        <span class="maintabTd maintabTd2 text-center">
                          <q-btn 
                            round unelevated 
                            size="6px"
                            color="amber" 
                            icon="search"
                            @click="linkChemClick({mdmChemMaterialId:hazard.mdmChemMaterialId, plantCd: hazard.plantCd}, 'chemEtc')" /></span>
                      </div>
                      <div class="maintabTable" v-show="mapObj.process.hazardChems.length === 0">
                        <!-- 화학자재 -->
                        <span class="maintabTd maintabTdgreen">{{$message('MSG0000128')}}</span>
                      </div>
                    </div>
                  </div>
                </template>
              </c-card>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 q-mt-sm" :style="!isTaskDetailShow ? 'padding-left:10px !important;padding-bottom:10px !important;' : ''">
              <!-- 업무현황 -->
              <c-card topClass="noborderCard" :title="(mapObj.process.processName ? '[' + mapObj.process.processName + ']' : '') + $comm.getLangLabel('LBL0000366')" 
                class="cardClassDetailForm main-map-card" :titleClass="isTaskDetailShow ? 'orange-5' : 'white'"  :height="isTaskDetailShow ? '485px' : '350px'" :isCardIcon="false">
                <template slot="card-detail">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <q-tabs
                      v-model="maintab2"
                      dense
                      align="justify"
                      :class="isTaskDetailShow ? 'text-white mainDashboardTab mainDashboardTabS' : 'text-white mainDashboardTab'"
                      :active-color="isTaskDetailShow ? 'white' : 'white'"
                      :indicator-color="isTaskDetailShow ? 'white' : 'white'"
                      inline-label
                      @input="tabClick"
                    >
                      <!-- 위험성평가 -->
                      <q-tab name="risk" :label="$comm.getLangLabel('LBL0000334')" />
                      <!-- MOC -->
                      <q-tab name="moc" :label="$comm.getLangLabel('LBL0000335')" />
                      <!-- 사고 -->
                      <q-tab name="accident" label="안전사고" />
                      <!-- 설비점검 -->
                      <q-tab name="equipCheck" :label="$comm.getLangLabel('LBL0000332')" />
                    </q-tabs>
                    <q-separator />
                    <q-tab-panels v-model="maintab2" animated class="maintabbody">
                      <q-tab-panel name="risk">
                        <div class="maintabTable" v-for="(assessRisk, assessRiskIdx) in mapObj.taskDetail.assessRisks"
                          :key="assessRiskIdx" v-show="assessRiskIdx < 7 && !isTaskDetailShow">
                          <span class="maintabTd" v-show="assessRiskIdx < 6">{{assessRisk.name}}</span>
                          <!-- … 외 -->
                          <!-- 건의 위험성평가가 존재합니다. -->
                          <span class="maintabTd maintabTdgreen" v-show="mapObj.taskDetail.assessRisks.length > 6 && assessRiskIdx == 6">{{$label('LBL0000358')}} {{mapObj.taskDetail.assessRisks.length - 6}} {{$message('MSG0000129')}}</span>
                        </div>
                        <div class="maintabTable" v-show="mapObj.taskDetail.assessRisks.length === 0 && !isTaskDetailShow">
                          <!-- 해당공정에 위험성평가가 존재하지 않습니다. -->
                          <span class="maintabTd maintabTdgreen">{{$message('MSG0000130')}}</span>
                        </div>
                        <div class="row" v-show="isTaskDetailShow">
                          <div :class="colType3">
                            <div class="maintabTable maintabTableTop">
                              <!-- 평가명 -->
                              <span class="maintabTr maintabTd6 text-center">{{$label('LBL0000367')}}</span>
                              <!-- 평가진행 -->
                              <!-- 건 수 -->
                              <span class="maintabTr maintabTd2 text-center">{{$label('LBL0000368')}}<br/>{{$label('LBL0000371')}}</span>
                              <!-- 위험등록부 -->
                              <!-- 건 수  -->
                              <span class="maintabTr maintabTd2 text-center">{{$label('LBL0000369')}}<br/>{{$label('LBL0000371')}}</span>
                              <!-- 미조치 -->
                              <!-- Punch-list -->
                              <span class="maintabTr maintabTd2 text-center">{{$label('LBL0000370')}}<br/>{{$label('LBL0000372')}}</span>
                            </div>
                            <div class="maintablebody maintablebody2">
                              <div class="maintabTable" v-for="(assess, assessIdx) in mapObj.taskDetail.assessRisks"
                                :key="assessIdx" v-show="mapObj.taskDetail.assessRisks.length > 0">
                                <span class="maintabTd maintabTd6 link-line padding-left10" @click="linkAssessClick({id:assess.id, docuType: assess.docuType, docuStep: assess.docuStep, ramTechniqueCd: assess.ramTechniqueCd, vendorFlag: assess.vendorFlag})">{{assess.name}}</span>
                                <span class="maintabTd maintabTd2 text-right">{{assess.actionCnt}}</span>
                                <span class="maintabTd maintabTd2 text-right">{{assess.riskRegisterCnt}}</span>
                                <span class="maintabTd maintabTd2 text-right">{{assess.notActionImprCnt}}</span>
                              </div>
                              <div class="maintabTable" v-show="mapObj.taskDetail.assessRisks.length === 0">
                                <!-- 해당공정에 위험성평가가 존재하지 않습니다. -->
                                <span class="maintabTd maintabTdgreen">{{$message('MSG0000130')}}</span>
                              </div>
                            </div>
                          </div>
                          <div :class="colType3">
                            <apexchart 
                              ref="assessRiskChart" 
                              height="400px" 
                              type="bar"
                              :width="assessRiskChart.chartWidth"
                              :options="assessRiskChart.chartOptions" 
                              :series="assessRiskChart.series"></apexchart>
                          </div>
                        </div>
                      </q-tab-panel>
                      <q-tab-panel name="accident">
                        <div class="maintabTable" v-for="(accident, accidentIdx) in mapObj.taskDetail.accidents"
                          :key="accidentIdx" v-show="accidentIdx < 7 && !isTaskDetailShow">
                          <span class="maintabTd" v-show="accidentIdx < 6">{{accident.name}}</span>
                          <!-- … 외 -->
                          <!-- 건의 사고가 존재합니다. -->
                          <span class="maintabTd maintabTdgreen" v-show="mapObj.taskDetail.accidents.length > 6 && accidentIdx == 6">{{$label('LBL0000358')}} {{mapObj.taskDetail.accidents.length - 6}} {{$message('MSG0000133')}}</span>
                        </div>
                        <div class="maintabTable" v-show="mapObj.taskDetail.accidents.length === 0 && !isTaskDetailShow">
                          <!-- 해당공정에 사고가 존재하지 않습니다. -->
                          <span class="maintabTd maintabTdgreen">{{$message('MSG0000134')}}</span>
                        </div>
                        <div class="row" v-show="isTaskDetailShow">
                          <div :class="colType3">
                            <div class="maintabTable maintabTableTop">
                              <span class="maintabTr maintabTd6 text-center padding-left10">재해명</span>
                              <span class="maintabTr maintabTd2 text-center">재해유형</span>
                              <span class="maintabTr maintabTd2 text-center">사고자</span>
                            </div>
                            <div class="maintablebody maintablebody2">
                              <div class="maintabTable" v-for="(assess, assessIdx) in mapObj.taskDetail.accidents"
                                :key="assessIdx" v-show="mapObj.taskDetail.accidents.length > 0">
                                <span class="maintabTd maintabTd6 link-line" @click="linkAccidentClick({id:assess.id, docuType: assess.docuType, docuStep: assess.docuStep})">{{assess.name}}</span>
                                <span class="maintabTd maintabTd2 text-center">{{assess.accident}}</span>
                                <span class="maintabTd maintabTd2 text-center">{{assess.accidentTypeName}}</span>
                                <span class="maintabTd maintabTd2 text-center">{{assess.occurrenceModeName}}</span>
                              </div>
                              <div class="maintabTable" v-show="mapObj.taskDetail.accidents.length === 0">
                                <!-- 해당공정에 사고가 존재하지 않습니다. -->
                                <span class="maintabTd maintabTdgreen">{{$message('MSG0000134')}}</span>
                              </div>
                            </div>
                          </div>
                          <div :class="colType3">
                            <apexchart 
                              ref="kindOcuurChart2" 
                              height="350px" 
                              type="polarArea"
                              :width="kindOcuurChart2.chartWidth"
                              :options="kindOcuurChart2.chartOptions" 
                              :series="kindOcuurChart2.series"></apexchart>
                          </div>
                        </div>
                      </q-tab-panel>
                    </q-tab-panels>
                  </div>
                </template>
              </c-card>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <component
          :is="impr.component"
        />
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <c-notice-popup></c-notice-popup>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import VueDraggableResizable from 'vue-draggable-resizable'

import selectConfig from '@/js/selectConfig';
export default {
  name: 'main-dashboard',

  components: { 
    VueDraggableResizable,
    apexchart: VueApexCharts
  },
  props: {
  },
  data() {
    return {
      mapTitle: '',
      fullscreen: false,
      maintab1: 'dept',
      maintab2: 'risk',
      isTaskDetailShow: false,
      setComplete: true,
      blur: {
        timer: null,
        isClear: false,
      }, // 점검진행상태
      mapObj: {
        activeTask: 1,
        envUrl: '',
        processUrl: '',
        url: '',
        interval: {
          obj: null,
          seconds: 3,
          isPause: false,
        },
        taskParam: {
          workPermitPeriod: [],
          assessRiskPeriod: [],
          accidentPeriod: [],
          equipCheckPeriod: [],
        },
        process: {
          processCd: '',
          processName: '',
          depts: [],
          chemprods: [],
          equips: [],
          hazardChems: [],
        },
        taskDetail: {
          assessRisks: [],
          accidents: [],
          equipChecks: [],
        },
        maps: [],
        slide: 0,
        date: '',
      },
      // 차트
      assessRiskChart: {
        chartOptions: {
          title: {
            text: '' //'위험성평가 실시 현황'
          },
          chart: {
            // type: 'bar',
            foreColor: "#fff",
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: [],
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              }
            }
          }
        },
        series: [{
          name: 'Count',
          data: [0, 0, 0]
          // data: [44, 55, 57]
        }],
        chartWidth: '80%',
      },
      kindOcuurChart2: {
        chartOptions: {
          title: {
            text: ''
          },
          chart: {
            // type: 'polarArea',
            foreColor: "#fff",
          },
          stroke: {
            colors: ['#fff']
          },
          labels: [], // ['추락','전도','충돌','낙하','비례','붕괴','협착','감전'],
          responsive: [{
            breakpoint: 1000,
            options: {
              chart: {
                width: 500
              },
            }
          }],
          yaxis: {
            title: {
              text: undefined
            },
              show: false
          },
          fill: {
            opacity: 0.8,
            colors: [
              '#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', 
              '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50'
              // , '#8BC34A',
            ]
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return   val;
              }
            }
          }
        },
        series: [],
        chartWidth: '80%',
      },
      workPermit: {
        slide: 0,
        date: '',
        listUrl: '',
        mapSrc: require('@/assets/images/no-image.png'),
        maps: [],
      },
      isHover: false,
      impr: {
        component: () => import(`${'./impr/imprRate.vue'}`),
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  activated() {
    this.intervalActionMap();
  },
  deactivated() {
    clearInterval(this.mapObj.interval.obj);
  },
  beforeDestroy() {
  },
  computed: {
    showMarkArea() {
      return this.setComplete &&
        ((this.mapObj.otherMaps && this.mapObj.otherMaps.length > 0)  
          || (this.mapObj.maps && this.mapObj.maps.length > 0))
    },
    mapRate() {
      return 1.2
    },
    setTaskDetailStyle() {
      let _style = {
        width: 0,
        position: 'absolute',
        left: 0,
        top: '35px',
        height: String(575 * this.mapRate + 20) + 'px',
      };
      if (this.isTaskDetailShow) {
        _style = {
          width: '50%',
          // transform: 'translate(100%, 0)',
          position: 'absolute',
          left: 0,
          top: '35px',
          height: String(575 * this.mapRate + 20) + 'px',
        }
      }
      return _style;
    },
    colType1() {
      return !this.isTaskDetailShow ? 'col-3' : 'col-2'
    },
    colType2() {
      return !this.isTaskDetailShow ? 'col-9' : 'col-10'
    },
    colType3() {
      return this.isTaskDetailShow ? 'col-6' : 'col-12'
    },
    colorItems() {
      return [
        // 계획수립
        { stepperMstCd: 'MCSC000005', stepperMstNm: this.$comm.getLangLabel('LBL0000381'), colorClass: 'blue' },
        // 점검중
        { stepperMstCd: 'MCSC000010', stepperMstNm: this.$comm.getLangLabel('LBL0000382'), colorClass: 'orange' },
        // 점검완료
        { stepperMstCd: 'MCSC000015', stepperMstNm: this.$comm.getLangLabel('LBL0000383'), colorClass: 'green' },
        // 지연
        { stepperMstCd: 'MCSC000020', stepperMstNm: this.$comm.getLangLabel('LBL0000384'), colorClass: 'red' },
      ]
    },
  },
  methods: {
    init() {
      // url setting
      this.mapObj.url = selectConfig.main.map.url
      this.mapObj.processUrl = selectConfig.main.mapProcess.url
      this.mapObj.envUrl = selectConfig.main.env.url
      this.workPermit.listUrl = selectConfig.main.workPermit.url
      
      this.setLangLabel();
      this.$comm.getComboItems('IIM_KIND_CD').then(_result => {
        this.kindOcuurChart2.chartOptions.labels = this.$_.map(_result, 'codeName')
        let _series = [];
        if (_result && _result.length > 0) {
          this.$_.forEach(_result, () => {
            _series.push(0);
          })
        }
        this.$set(this.kindOcuurChart2, 'series', _series)
      });
      // list setting
      this.getMapPriodParam();
      this.getMapData();
    },
    setLangLabel() {
      this.assessRiskChart.chartOptions.title.text = this.$comm.getLangLabel('LBL0000385')  //'위험성평가 실시 현황'
      this.assessRiskChart.chartOptions.xaxis.categories = [
        this.$comm.getLangLabel('LBL0000340'), //'평가진행 건 수', 
        this.$comm.getLangLabel('LBL0000341'), //'위험등록부 건 수', 
        this.$comm.getLangLabel('LBL0000342'), //'미조치 Punch-list 건 수'
      ]
      let thisVue = this;
      this.assessRiskChart.chartOptions.tooltip.y.formatter = (val) => {
        return val + " " + thisVue.$comm.getLangLabel('LBL0000389') //" 건"
      }
      this.kindOcuurChart2.chartOptions.title.text = this.$comm.getLangLabel('LBL0000395') //'사고종류별 발생 건수'
      this.kindOcuurChart2.chartOptions.tooltip.y.formatter = (val) => {
        return val + " " + thisVue.$comm.getLangLabel('LBL0000389') //" 건"
      }
    },
    getMapPriodParam() {
      /**
       * 초기 지도 정보를 불러올 때에는 기간 정보가 넘어가지 않게 처리
       */
      this.$http.url = this.mapObj.envUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$set(this.mapObj.taskParam, 'workPermitPeriod', [_result.data[0].start, _result.data[0].end])
        this.$set(this.mapObj.taskParam, 'assessRiskPeriod', [_result.data[1].start, _result.data[1].end])
        this.$set(this.mapObj.taskParam, 'accidentPeriod', [_result.data[3].start, _result.data[3].end])
        this.$set(this.mapObj.taskParam, 'equipCheckPeriod', [_result.data[4].start, _result.data[4].end])
      },);
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
    getMapData() {
      let _height = 575 * this.mapRate
      this.$refs['carousel'].style.setProperty("--map-height", String(_height) + 'px');
      let defaultPlantCd = window.sessionStorage.getItem('defaultPlantCd');
      this.$http.url = this.mapObj.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: defaultPlantCd,
        workPermitPeriod: this.mapObj.taskParam.workPermitPeriod,
        assessRiskPeriod: this.mapObj.taskParam.assessRiskPeriod,
        accidentPeriod: this.mapObj.taskParam.accidentPeriod,
        equipCheckPeriod: this.mapObj.taskParam.equipCheckPeriod,
      }
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          this.mapObj.maps = _result.data;
          this.$_.forEach(this.mapObj.maps, map => {
            if (map.maps && map.maps.length > 0) {
              this.$_.forEach(map.maps, item => {
                this.$set(item, 'isHover', false)
              });
            }
            if (map.otherMaps && map.otherMaps.length > 0) {
              this.$_.forEach(map.otherMaps, item => {
                this.$set(item, 'isHover', false)
              });
            }
          })
          this.$refs['carousel'].style.setProperty("--map-ratio", this.mapObj.maps[0].ratio);
        }
      },);
    },
    intervalActionMap() {
      this.mapObj.interval.obj = setInterval(() => {
        this.mapObj.interval.seconds--
        
        if (this.mapObj.interval.seconds === 0) {
          if (this.mapObj.activeTask === 5) {
            this.mapObj.activeTask = 1
          } else {
            this.mapObj.activeTask++;
          }
          this.mapObj.interval.seconds = 3;
        }
      }, 1000);
    },
    pause() {
      this.mapObj.interval.isPause = !this.mapObj.interval.isPause
      if (this.mapObj.interval.isPause) {
        clearInterval(this.mapObj.interval.obj);
      } else {
        this.intervalActionMap()
      }
    },
    // blurTaskDetail() {
    //   this.blur.timer = setTimeout(() => {
    //     this.isTaskDetailShow = false;
    //   }, 150);
    //   if (this.blur.isClear) {
    //     clearTimeout(this.blur.timer);
    //     this.blur.isClear = false;
    //   }
    // },
    tabClick() {
      if (this.isTaskDetailShow) {
        // 차트가 보이는 경우
        setTimeout(() => {
          switch(this.maintab2) {
            case 'risk': // 추가/신규
              this.assessRiskChart.chartWidth = '100%';
              this.$refs['assessRiskChart'].refresh();
              break;
            case 'accident': // 삭제
              this.kindOcuurChart2.chartWidth = '100%';
              this.$refs['kindOcuurChart2'].refresh();
              break;
            default:
              break;
          }
        }, 200);
      }
    },
    openTaskDetail(isOpen) {
      if (isOpen) {
        this.isTaskDetailShow = true;
      } else {
        this.isTaskDetailShow = !this.isTaskDetailShow
      }
      if (this.isTaskDetailShow) {
        this.tabClick();
      }
    },
    onClickProcess(mp) {
      /**
       * 공정 클릭 시 오른쪽에 설명 영역 조회
       * 
       *  1. 공정현황
       *   - 부서
       *   - 근로자수
       *   - 생산품
       *   - 설비목록
       *   - 유해위험물질 목록
       */
      // this.openTaskDetail(true)
      this.$http.url = this.mapObj.processUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        processCd: mp.processCd,
      }
      this.$http.request((_result) => {
        this.mapObj.process = _result.data;
        this.$set(this.mapObj.process, 'processCd', mp.processCd)
        this.$set(this.mapObj.process, 'processName', mp.processName)
      },);

      /**
       * 업무현황 정리
       */
      this.mapObj.taskDetail.assessRisks = this.$_.filter(mp.tasks, { taskType: '2' });
      this.mapObj.taskDetail.accidents = this.$_.filter(mp.tasks, { taskType: '4' });
      this.mapObj.taskDetail.equipChecks = this.$_.filter(mp.tasks, { taskType: '5' });

      /**
       * 차트
       */
      // 위험성평가
      let assessData = {
        actionCnt: 0,
        riskRegisterCnt: 0,
        notActionImprCnt: 0,
      }
      this.$_.forEach(this.mapObj.taskDetail.assessRisks, item => {
        assessData.actionCnt += item.actionCnt
        assessData.riskRegisterCnt += item.riskRegisterCnt
        assessData.notActionImprCnt += item.notActionImprCnt
      })
      this.$set(this.assessRiskChart.series[0], 'data', [
        assessData.actionCnt,
        assessData.riskRegisterCnt,
        assessData.notActionImprCnt,
      ])
      // 사고
      let _series = [];
      this.$_.forEach(this.kindOcuurChart2.chartOptions.labels, item => {
        _series.push({
          name: item,
          count: 0,
        })
      })
      this.$_.forEach(this.mapObj.taskDetail.accidents, item => {
        if (item.accidentKindName) {
          let splitData = item.accidentKindName.split(',');
          if (splitData) {
            this.$_.forEach(splitData, _item => {
              this.$_.find(_series, { name: _item }).count++;
            })
          }
        }
      })
      this.$set(this.kindOcuurChart2, 'series', this.$_.map(_series, 'count'))
    },
    setStyleTaskArea(mp) {
      let style = {
        transform: 'translate(' + String(mp.x * this.mapRate) + 'px, ' + String(mp.y * this.mapRate + 30) + 'px)',
        'z-index': 1,
        'user-select': 'auto',
        width: '1px',
        height: '1px',
        position: 'absolute'
      }
      if (mp.isHover) {
        style['z-index'] = 7
      }
      return style;
    },
    imgHeight(map) {
      return String(map.ratio * 800) + 'px'
    },
    linkEquipClick(row) {
      /**
       * 설비 클릭 시
       */
      this.popupOptions.title = this.$comm.getLangLabel('LBL0000396') //'설비 상세';
      this.popupOptions.param = {
        equipmentCd: row.equipmentCd,
        plantCd: row.plantCd,
        psmFlag: row ? row.psmFlag : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    linkChemClick(row, tabName) {
      this.popupOptions.title = this.$comm.getLangLabel('LBL0000397') //'화학자재 상세';
      this.popupOptions.param = {
        mdmChemMaterialId: row.mdmChemMaterialId,
        plantCd: row.plantCd,
        tabName: tabName, // ? 'chemPsm' : 'chemEtc',
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/mam/chemDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    linkAssessClick(row) {
      this.popupOptions.title = this.$comm.getLangLabel('LBL0000398') //'위험성평가 상세'
      this.popupOptions.param = {
        ramRiskAssessmentPlanId: row.id,
        ramStepCd: row.docuStep,
        vendorFlag: row ? (row.vendorFlag > 0) : false,
        ramTechniqueCd: row.ramTechniqueCd
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/riskDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    linkAccidentClick(row) {
      this.popupOptions.title = 'LBL0000400' //"공정사고 상세"
      this.popupOptions.param = {
        iimAccidentId: row ? row.id : '',
        stepCd: row ? row.docuStep : '',
        count: 0,
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/iim/processAccidentDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    // getWorkPermit() {
    //   let defaultPlantCd = window.sessionStorage.getItem('defaultPlantCd');
    //   this.$http.url = this.workPermit.listUrl;
    //   this.$http.type = 'GET';
    //   this.$http.param = {
    //     plantCd: defaultPlantCd,
    //     date: this.workPermit.date
    //   }
    //   this.$http.request((_result) => {
    //     this.workPermit.maps = _result.data;
    //     this.$_.forEach(this.workPermit.maps, map => {
    //       this.$_.forEach(map.maps, item => {
    //         this.$set(item, 'isHover', false)
    //       });
    //     })
    //   },);
    // },
    workPermitIconName(resultType) {
      let icon = '';
      switch(resultType.sopWorkTypeCd) {
        case 'SPT0000001': // 일반
          icon = 'engineering';
          break;
        case 'SPT0000005': // 화기
          icon = 'local_fire_department';
          break;
        case 'SSWT000001': // 밀폐공간
          icon = 'dangerous';
          break;
        case 'SSWT000005': // 정전
          icon = 'flash_off';
          break;
        case 'SSWT000010': // 방사선
          icon = 'warning';
          break;
        case 'SSWT000015': // 굴착
          icon = 'hardware';
          break;
        case 'SSWT000020': // 고소
          icon = 'stairs';
          break;
        case 'SSWT000025': // 중장비
          icon = 'agriculture';
          break;
      }
      return icon;
    },
    workPermitbadgeColor(swpStepName) {
      let icon = '';
      switch(swpStepName) {
        case '승인완료':
          icon = 'light-blue';
          break;
        case '연장':
          icon = 'amber';
          break;
        case '작업완료':
          icon = 'light-green';
          break;
      }
      return icon;
    },
    openWorkPermit(item) {
      if (!item) return;
      this.popupOptions.isFull = true;
      this.popupOptions.title = 'LBL0000938' //'작업허가서 상세';
      this.popupOptions.param = {
        sopWorkPermitId: item.sopWorkPermitId,
        permitTypeCd: item.permitTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    /* eslint-disable no-unused-vars */
    getWidth(item) {
      let width = 0;
      this.$_.forEach(item.resultTypes, resultType => {
        width += (resultType.sopWorkTypeName.length * 10) + 60
      })
      return width + 'px'
    },
    getLeft(item) {
      /**
       * 지도의 크기는 800
       */
      let left = 0;
      let nonDetailLocation = item.locationXcoordinate + (item.resultTypes.length * 40)
      let detailLocation = item.locationXcoordinate + 250
      let calLocation = nonDetailLocation > detailLocation ? nonDetailLocation : detailLocation
      if (calLocation > 800) {
        left = 800 - calLocation
      }
      return left + 'px'
    },
    getTop(item) {
      /**
       * 지도의 높이는 594
       */
      let top = 30;
      let calLocation = item.locationYcoordinate + (item.resultTypes.length * 76)
      if (calLocation > 594) {
        top = 594 - calLocation
      }
      return !item.isHover ? '30px' : top + 'px'
    },
    goPage(_page) {
      this.$comm.movePage(this.$router, _page)
    },
    clickFullScreen() {
      let element = this.$refs['main-dashborad']
      if (this.fullscreen === false) {
        this.fullscreen = true;
        let requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;

        if (requestMethod) { // Native full screen.
          requestMethod.call(element);
        }
      } else {
        this.fullscreen = false;
        let requestMethod = document.exitFullscreen || document.cancelFullScreen || document.webkitCancelFullScreen || document.mozCancelFullScreen || document.msExitFullscreen;
        if (requestMethod) { // Native full screen.
          requestMethod.call(document);
        }		
      }
    },
    mapTitleChange(_title) {
      alert();
      this.mapTitle = _title;
    }
  }
};
</script>
<style lang="sass">
  .main-body:fullscreen 
    overflow: scroll !important
  .main-body:-ms-fullscreen 
    overflow: scroll !important
  .main-body:-webkit-full-screen 
    overflow: scroll !important
  .main-body:-moz-full-screen 
    overflow: scroll !important
  
  .mainlayoutback
    background-color: #161d31 !important
  .mainlayoutback .row > .col-sm-12:not(:last-child)
    padding-right: 0px !important
  .topmainlayer
    border-radius: 10px 10px 10px 10px !important
    height: 76px
    background-color: #283046 !important
    display: block
    div.title
      padding: 10px 0px 0px 30px
      font-weight: 600
      color: #7367f0
      font-size: 20px
      display: inline-block
    .title1
      color: #fff
      font-size: 22px
    .title2
      color: #fff
      font-size: 32px
    .title3
      color: #7367f0
      font-size: 32px
    .mainbtns
      float: right !important
      display: inline-block
    .mainbtnscreen
      margin-top: 15px
      padding-right: 10px
      float: right !important
      display: inline-block
    .today
      color: #fb8c00
      padding-top: 35px
      padding-left: 23px
      font-weight: 600
  .maincardlayer
    background-color: #161d31 !important
  .maincardheader
    border-radius: 10px 10px 0 0 !important
    background-color: #343d55 !important
    .customdatelabel
      margin-top: 0px !important
    .cdatetimepic .inputClass
      width: 220px !important
      max-width: 220px !important
      background-color: #343d55 !important
      color: #fff !important
      border-width: 0px !important
    .cdatetimepic .mx-input-wrapper
      padding: 0px 3px !important
      margin-bottom: 0px !important
      .mx-icon-clear
        color: #fff !important
      .mx-icon-calendar
        color: #fff !important
  .mainmapstart
    float: right
    margin-right: 20px
  .main-header-input
    width: 220px !important
  .maincardbody
    background-color: #283046
    border-radius: 0 0 10px 10px !important
    
  .mainchild
    margin-top: -75px !important
    padding: 20px 20px 5px 20px
  .maincard
    margin-right: 6px
    margin-bottom: 6px
  .maincard:hover
    margin-top: -10px
  .cardcontents
    margin-top: 6px
    margin-bottom: 6px
    margin-right: 6px
    .card-body
      padding: 10px !important
    .card-header:not(.non-label-icon):before
      font-family: "Material Icons"
      content: "\e876"
    .card-header
      height: 40px
      color: #1ab394
      background: #f8f8f8
      display: inline-block
      padding-top: 8px
      .card-more
        float: right !important
        cursor: pointer
        color: #d2d2d2
      .card-more:hover
        color: #1ab394
  .maincard .card-body
    background: #fff
    border-radius: 5px
    padding: 15px !important
    .fw-bold
      font-weight: 600
    .material-icons
      font-size: 3em
      color: #ddd
      cursor: pointer
  .maincard .card-body.border-color-blue
    border-left: 4px solid #0061f2
  .maincard .card-body.border-color-red
    border-left: 4px solid #d92550
  .maincard .card-body.border-color-yullow
    border-left: 4px solid #f7b924
  .maincard .card-body.border-color-green
    border-left: 4px solid #3ac47d

.mainMarkImage
  padding: 0px !important
  text-align: center
  font-size: 20px
  height: 30px !important
  position: absolute
  padding: 16px
  color: #C10015
  z-index: 3 !important
  // background: rgba(0,0,0,0.47)
  .markImage-abbreviation-parent
    // width:240px
    text-align: start
    position: absolute
    .markImage-abbreviation
      cursor: pointer
      display: inline-block
      // min-width:60px
      // max-width:250px
      width: auto
      border-left: 1px solid #757575
      border-right: 1px solid #757575
      border-top: 1px solid #757575
      border-bottom: 1px solid #757575
      border-radius: 5px
      .markImage-tran
        border-radius: 5px
      .markImage-abbreviation-banner
        border-right: 1px solid #757575
        display: inline-block
        text-align: center
        min-width:60px
        padding: 1px !important
        border-radius: 5px 0px 0px 5px
        i
          font-size: 20px
        div
          font-size: 12px !important
      .markImage-abbreviation-banner.last-banner
        border-right: 0
        border-radius: 0px 5px 5px 0px
      .markImage-abbreviation-plus
        display: inline-block
        text-align: center
        min-width:40px
      .markImage-abbreviation-banner-detail
        border-radius: 5px
        .q-banner__avatar i
          font-size: 20px !important
        .q-banner__content
          font-size: 12px !important
.markImage-abbreviation > div
  -webkit-animation: fadeInFromNone 0.1s ease-out
  -moz-animation: fadeInFromNone 0.1s ease-out
  -o-animation: fadeInFromNone 0.1s ease-out
  animation: fadeInFromNone 0.1s ease-out


// .mark-list-enter-active, .mark-list-leave-active
//   transition: all 0.1s
.mark-list-enter, .mark-list-leave-to
  opacity: 0
  transform: translateY(5px)

.caption-work
  font-size: 0.9em
  // font-style: italic
  white-space: normal
  color: #555
  margin-top: 4px

.caption-work2
  font-size: 0.9em
  // font-style: italic
  white-space: normal
  color: #fff
  padding: 10px

.workPermit-carousel
  border-radius: 10px
  height: var(--map-height) !important
  .q-carousel__slide
    width: calc(var(--map-ratio) * var(--map-height)) !important
  .q-carousel--padding, .q-carousel__slide
    padding: 0 !important
  .q-carousel__arrow
    transition: all 0.5s
    opacity: 0.2
  .q-carousel__arrow:hover
    transition: all 0.5s
    opacity: 0.8
  .q-carousel__slides-container
    .q-panel.scroll
      overflow: hidden !important

.main-header-input
  margin-top: -5px
  padding-right: 5px
  .customDate
    padding-bottom: 0 !important
    .q-field__inner
      padding: 0 !important
.maintabbody
  background-color: #283046
.padding-left10
  padding-left: 10px !important

@-webkit-keyframes fadeInFromNone
  0%
    opacity: 0
  50%
    opacity: 0
  70%
    opacity: 0.5
  100%
    opacity: 1

@-moz-keyframes fadeInFromNone
  0%
    opacity: 0
  50%
    opacity: 0
  70%
    opacity: 0.5
  100%
    opacity: 1

@-o-keyframes fadeInFromNone
  0%
    opacity: 0
  50%
    opacity: 0
  70%
    opacity: 0.5
  100%
    opacity: 1

@keyframes fadeInFromNone
  0%
    opacity: 0
  50%
    opacity: 0
  70%
    opacity: 0.5
  100%
    opacity: 1


.main-map-card 
  border-radius: 10px !important
  border-width: 0px !important
  background-color: #161d31 !important
  .q-tab--inactive
    border: 1px solid #fff
    margin: 0px 2px !important
    border-radius: 5px !important
  .q-tab--active
    border: 1px solid #fff
    margin: 0px 2px !important
    border-radius: 5px !important
    background-color: #fff !important
    color:  #161d31 !important
  .q-tab__indicator
    display: none !important
  .customCardbody
    margin: 0px !important
    .main-card-head-text
      font-weight: 600
      font-size: 0.9rem
      line-height: 26px
  .cardhead
    height: 41px !important
    background-color: #343d55 !important
    color: #fff !important
    padding-top: 5px !important
    font-size: 1.1em !important
  .customCardbody
    background-color: #283046 !important
    border-radius: 0 0 10px 10px !important
    padding-top: 10px !important
  .q-separator--horizontal
    display: none !important
  .mainDashboardTab
    .q-tab-panel
      display: table
      width: 100%
    .q-tab__label
      font-size: 1.0em !important
      font-weight: 600
  .mainDashboardTabS
    .q-tab__label
      font-size: 1.1em !important
  .maintabTable
    width: 100%
    display: inline-flex
    color: #fff !important
  .maintabTableTop
    border-top: 1px solid rgb(255,255,255, 0.3)
    border-left: 1px solid rgb(255,255,255, 0.3)
    border-right: 1px solid rgb(255,255,255, 0.3)
  .maintabTd
    font-size: 1.1em !important
    padding: 8px 5px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    width: 100%
  .maintabTr
    font-size: 1.1em !important
    padding: 8px 5px
    width: 100%
    background-color: #161d31 !important
  .maintabTd7
    width: 70%
  .maintabTd6
    width: 60%
  .maintabTd5
    width: 50%
  .maintabTd4
    width: 40%
  .maintabTd3
    width: 30%
    text-align: right
  .maintabTd2
    width: 20%
  .maintabTd1
    width: 10%
  .maintabTdgreen
    color: #28c76f !important
    font-size: 1.05em !important
    text-align: center
  .maintablebody
    height: 250px
    background-color: #343d55 !important
    border-radius: 0 0 10px 10px !important
    overflow-y: auto
    border-bottom: 1px solid rgb(255,255,255, 0.3)
    border-left: 1px solid rgb(255,255,255, 0.3)
    border-right: 1px solid rgb(255,255,255, 0.3)
  .maintablebody2
    height: 300px !important
</style>
<style scoped>
.widget-numbers {
  font-size: 20px;
}
.impr-item {
  height: 100% !important;
}
.my-class {
  /* background-color: #9E9E9E; */
  border: 2px solid rgba(255, 255, 255, 0.7);
  background-color: rgba(40, 48, 70, 0.2);
  border-radius: 10px;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  position: absolute;
  text-align: center;
  color: black;
  cursor: pointer;
  /* z-index: 1 !important; */
}
.my-class div {
  width: 100%;
  border-radius: 10px 10px 0 0;
  color: #fff;
  background-color: rgba(40, 48, 70, 0.6);
}
.my-active-class {
  /* opacity: 0.5; */
  background-color: rgba(115,103,240,0.5);
  /* color:azure; */
}
.chip-task {
  margin-left: 8px !important;
}
.mainlayoutback .task-detail {
  z-index: 5;
  width:250px;
  position: relative;
}
.mainlayoutback .task-detail .q-banner {
  background-color: #283046;
  border-radius: 5px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  padding: 0px !important;
}
.mainlayoutback .task-detail .q-banner .col-auto {
  padding: 0px !important;
}
.mainmapbannerTitle {
  width: 100%;
  color: #fff;
  background-color: #343d55;
  padding: 10px !important;
  border-radius: 3px;
}
.open-task {
  left: 100%; 
  width: 80%;
  transform: translate(-100%, 0);
  position: absolute;
  z-index: 1000;
  padding: 10px !important;
  border-radius: 15px !important;
  background: #fff;
  z-index: 2001;
}
.maintablebody::-webkit-scrollbar {
  width: 10px;
}
.maintablebody::-webkit-scrollbar-track {
  background: #343d55 !important;
  border-radius: 0 0 10px 0;
}

.maintablebody::-webkit-scrollbar-thumb {
  background-color: #161d31 !important;
  background-image: linear-gradient(315deg, #161d31 0%, #161d31 74%);
}
.link-line {
  text-decoration: underline;
  cursor: pointer;
}
.mapTitleDiv {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 10px;
  background-color: #283046 !important;
  color: #fff;
  padding: 5px 10px;
  font-weight: 700;
  z-index: 2000;
  font-size: 12px;
}
</style>